<template>
    <div class="login" :style="{backgroundImage:'url('+loginBg+')'}">
        <img alt="icon" class="icon" :src="icon">
        <div class="loginBox">
            <div style="width:3.75rem;margin:0.47rem auto 0 auto;">
                <div class="title">体态博士管理系统</div>
                <div class="checkWay">
                    <div :class="{'psdActive':isPassword,'psdNoActive':!isPassword}" @click="toPsd">密码登录</div>
                    <div :class="{'codeActive':!isPassword,'codeNoActive':isPassword}" @click="toCode">验证码登陆</div>
                </div>
                <!--密码登录 -->
                <div class="password" v-if="isPassword">
                    <el-form :model="userForm" :rules="rules" ref="userForm" label-width="0px">
                        <el-form-item prop="account">
                        <el-input
                            placeholder="请输入您的账号"
                            prefix-icon="el-icon-user"
                            v-model="userForm.account" style="margin-top:0.38rem;width:3.75rem">
                        </el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input
                                show-password
                                placeholder="请输入您的密码"
                                prefix-icon="el-icon-lock"
                                v-model="userForm.password" style="margin-top:0.38rem !important;width:3.75rem">
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="ycode" style="position: relative">
                            <el-input
                                placeholder="请输入验证码"
                                prefix-icon="iconfont icon-yanzhengma-xianxing"
                                v-model="userForm.ycode" style="margin-top:0.38rem !important;width:3.75rem">
                            </el-input>
                            <div class="divIdentifyingCode" @click="getYcode">
                                 <img ref="yycode" class="imgIdentifyingCode" alt="点击更换" :src="xxcodeImg"/>
                                 <span class="next" @click="getYcode">看不清?换一张</span>
                            </div>     
                        </el-form-item>
                    </el-form>
                    <div class="forget" @click="resetPSW">忘记密码</div>
                    <div class="submit" @click="startLogin('userForm')">登录</div>
                    <div class="other">
                        <span style="color:#AEAEAE">没有账号？</span>
                        <span @click="apply" style="color:#0090FF;cursor:pointer;">账号申请</span>
                    </div>
                </div>
                <!-- 验证码登录 -->
                <div class="codeL" v-if="!isPassword">
                    <el-form :model="phoneForm" :rules="rules" ref="phoneForm" label-width="0px">
                        <el-form-item prop="mobile" >
                            <el-input placeholder="请输入手机号" v-model="phoneForm.mobile"  class="setPhone" style="width:3.75rem;">
                                        <i slot="prepend" class="iconfont icon-yanzhengma-xianxing"></i>
                                        <el-select v-model="select" slot="prepend" placeholder="+86" style="width:0.8rem;margin-left:1px;" >
                                            <el-option :value="value[0]"><span>中国大陆</span><span>{{value[0]}}</span></el-option>
                                            <el-option :value="value[1]"><span>中国香港</span><span>{{value[1]}}</span></el-option>
                                            <el-option :value="value[2]"><span>中国澳门</span><span>{{value[2]}}</span></el-option>
                                            <el-option :value="value[3]"><span>中国台湾</span><span>{{value[3]}}</span></el-option>
                                        </el-select>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <div class="phoneCodeBox">
                            <el-input  
                                    placeholder="请输入短信验证码"
                                    v-model="phoneForm.phoneCode"
                                    prefix-icon="el-icon-key"
                                    oninput ="value=value.replace(/[^0-9A-Za-z.]/g,'')"
                                    :disabled="isSendCode"
                                    style="width:2.48rem;">
                            </el-input>
                            <div class="getCode1" v-if="coding.isgetCode" @click="getPhoneCode('phoneForm')">获取验证码</div>
                            <div class="getCode2" v-if="coding.isReSend">{{reSendTime}}s重新发送</div>
                            <div class="getCode3" v-if="coding.reSend" @click="getPhoneCode('phoneForm')">重新发送</div>
                    </div>
                    <div class="submit" @click="loginByCode('phoneForm')">登录</div>
                    <div class="other">
                        <span style="color:#AEAEAE">没有账号？</span>
                        <span @click="apply" style="color:#0090FF;cursor:pointer;">账号申请</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- ICP -->
        <div class="icp"> <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2021139896号</a></div>
        
    </div>
</template>
<script>
import {loginByPassword ,getSms,loginByCode} from '@/api/login.js'
import {getMenuList} from '@/api/userRoot.js'
export default {
    data(){
        //自定义校验规则
        var checkAccount = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('账号不能为空'));
                }
                setTimeout(() => {
                    if (/[^0-9A-Za-z]/g.test(value)) {
                        callback(new Error('请输入英文或者数字组合'));
                } else {
                    if (value.length<8 || value.length >20) {
                        callback(new Error('账号长度必须在8-20字符以内'));
                    } else {
                        callback();
                    }
                }
                }, 500);
            };
        var checkPassword = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('密码不能为空'));
                } else {
                    if (value.length<6 || value.length >20) {
                        callback(new Error('密码长度为6-20个字符'));
                    }
                        callback();
                    }
            };
        var checkYcode = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('验证码不能为空'));
                } else {
                        callback();
                    }
            };
        var checkPhone = (rule, value, callback) => {
            let reg =/^[1][3,4,5,7,8][0-9]{9}$/;
                if (!value) {
                    callback(new Error('手机号不能为空'));
                } else {
                    if (!reg.test(value)) {
                        callback(new Error('手机号码格式不正确'));
                    }
                        callback();
                    }
            };

        return{
            path:null,
            //logo
            loginBg:'https://sz.drbodyhealth.com/loginBg.png',
            icon:'https://sz.drbodyhealth.com/logo.png',
            //账号密码
            userForm:{
                account:'',
                password:'',
                ycode:''
            },
            //验证码登录
            phoneForm:{
                 mobile:"",
                 phoneCode:"",
            },
            // 校验规则
            rules:{
                    account:[{ validator: checkAccount, trigger: 'blur' }],
                    password:[{ validator: checkPassword, trigger: 'blur' }],
                    ycode:[{validator:checkYcode, trigger: 'blur'} ],
                    mobile:[{validator: checkPhone, trigger: 'blur' }],
                },
            //是否发送了验证码
            isSendCode:true,
            // isPassword
            isPassword:true,
            // 号码区域
            select:"",
            //value
            value:["+86","+852","+853","+886"],
            // 验证码状态
            coding:{
                isgetCode:true,
                isReSend:false,
                reSend:false,
            },
            //reSendTime
            reSendTime:null,
            xxcodeImg:"/api/captcha/image",

        }
    },
   mounted(){
        this.getYcode();
   },
    methods:{

        toPsd(){
            this.isPassword = true;
        },
        toCode(){
            this.isPassword = false;
        },
        //重置密码
        resetPSW(){
            //console.log("reset")
            this.$router.push({path:'/reset'})
        },
        // 开始登录--账号登录
        startLogin(formName){
            this.$refs[formName].validate((valid) => {
                    if (valid) {
                        //login
                        loginByPassword({
                            username: this.userForm.account,
                            password: this.userForm.password,
                            imageCode:this.userForm.ycode,
                        }).then((res)=>{
                            if(res.data.status == 200){
                                localStorage.setItem('token',res.data.data.token);
                                localStorage.setItem('userName',res.data.data.userName);
                                //
                                getMenuList().then(res=>{
                                    if(res.data.status==200){
                                        var data = res.data.data;
                                        var firstPath = data[0].children[0].path;
                                        //console.log("fist",firstPath)
                                        this.$router.push({path:`${firstPath}`});
                                        this.$message({
                                            message: '登录成功',
                                            type: 'success',
                                        });
                                    }else{
                                        this.$message.error(res.data.message);
                                    }
                                })
                               
                                
                            }else{
                                this.$message.error(res.data.message);
                            }
                        }).catch(res=>{
                            this.$message.error(res)
                        })
                        } else {
                                this.$message.error('请正确填写账号或密码');
                                return false;
                            }
                    });
                },
        // 验证码登录
        loginByCode(formName){
            this.$refs[formName].validate((valid) => {
                if(valid && (this.phoneForm.phoneCode)){
                    loginByCode({
                        "mobile": this.phoneForm.mobile,
                        "mobileCode": this.phoneForm.phoneCode
                    }).then(res=>{
                        //console.log("验证码登录是否成功？",res.data);
                        if(res.data.status == 200){
                            localStorage.setItem('token',res.data.data.token);
                            getMenuList().then(res=>{
                                    if(res.data.status==200){
                                        var data = res.data.data;
                                        var firstPath = data[0].children[0].path;
                                        //console.log("fist",firstPath)
                                        this.$router.push({path:`${firstPath}`});
                                        this.$message({
                                            message: '登录成功',
                                            type: 'success',
                                        });
                                    }else{
                                        this.$message.error(res.data.message);
                                    }
                                })
                        }else{
                            this.$message.error(res.data.message);
                        }
                    }).catch(res=>{
                            this.$message.error(res)
                        })
                }else{
                    this.$message.error('请正确填写手机号码或验证码');
                    return false;
                }
            }) 
        },
        //获取验证码
        getPhoneCode(formName){
            //判断
            this.$refs[formName].validate((valid) => {
            if(valid){
                
                const that = this ;
                // 发送验证码
                getSms({
                    "mobile": that.phoneForm.mobile
                }).then( res=>{
                    if(res.data.status != 200){
                         this.$message.error(res.data.message);
                        that.getPhoneCode(formName);
                    }else{       
                            that.coding.isgetCode =false;
                            that.coding.isReSend = true;
                            that.coding.reSend = false ;
                            //可以输入验证码
                            this.isSendCode =false;
                            if(res.data.status == 200){
                                this.$message({
                                    message: '验证码发送成功',
                                    type: 'success'
                                });
                                for(var i =0;i<=60;i++){
                                    (function(i){
                                        setTimeout(()=>{
                                        that.reSendTime = (60-i) ;
                                        if(that.reSendTime == 0){
                                            that.coding.isgetCode =false;
                                            that.coding.isReSend = false;
                                            that.coding.reSend = true ;
                                        }
                                        },i*1000)
                                    })(i)
                                };
                            }else{
                                this.$message.error(res.data.message);
                            }
                    }
                }).catch(res=>{
                    this.$message.error(res);
                        });
                
              } else {
                  this.$message.error('手机号码格式不正确');
                    return false;
              }
            })
        },
        //申请账号
        apply(){
            alert("二期功能，带开发！");
        },
        //获取密码登录验证码
        getYcode(){
           this.$refs.yycode.setAttribute('src',this.xxcodeImg)
        },



    
        
    }
}
</script>
<style scoped lang="stylus">
/deep/i.el-input__icon.el-icon-user {
    color: #00CCCC;
}
/deep/i.el-input__icon.el-icon-lock {
    color: #00CCCC;
}
/deep/i.el-input__icon.el-icon-key {
    color: #00CCCC;
}
/deep/.el-input-group__prepend {
    background-color: #fff;
}

/deep/.el-input-group__append, .el-input-group__prepend{
    padding-left: .08rem !important;
}

/deep/.el-form-item {
    margin-bottom: 0;
}
/deep/.el-icon-mobile-phone:before {
    color: #00CCCC;
}
/deep/.icon-yanzhengma-xianxing:before{
    color: #00CCCC
}
/deep/.el-icon-arrow-up:before{
    margin-left:0
}
/deep/.el-input__inner{
    height: .39rem !important;
    border-radius: 2px !important;
    position: relative !important;
}
/deep/.el-input-group__prepend{
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

// 登录改变样式
.psdActive
    font-size: .16rem
    font-family: Source Han Sans CN
    width: .7rem
    color: #353535
    font-weight: bold
    text-align: center
    cursor pointer
    position relative
    &::before
        width: 100%
        height: .02rem
        content: ""
        position: absolute
        border-radius: .03rem
        left: 0
        bottom:  -0.03rem
        background-color: #00CCCC 
.psdNoActive
    font-size: .16rem
    font-family: Source Han Sans CN 
    width: .7rem
    color: #858585
    text-align: center
    cursor pointer
    
.codeActive
    font-size: .16rem
    font-family: Source Han Sans CN
    width: .86rem
    color: #353535
    margin-left: .31rem
    font-weight: bold
    text-align: center 
    cursor pointer
    position relative
    &::before
        width: 100%
        height: .02rem
        content: ""
        position: absolute
        border-radius: .03rem
        left: 0
        bottom: -0.03rem
        background-color: #00CCCC 
.codeNoActive
    font-size: .16rem
    font-family: Source Han Sans CN
    width: .86rem
    color: #858585
    margin-left:.31rem
    text-align: center
    font-weight: bold
    cursor pointer
.getCode1
    width: 1.08rem
    height: .39rem
    margin-left: .19rem
    background-color: #00CCCC
    border-radius: .02rem
    line-height: .39rem
    text-align: center
    font-size: .14rem
    color: #fff
    cursor pointer
.getCode2
    width: 1.08rem
    height: .39rem 
    margin-left: .19rem
    background-color: #F4F4F4
    border-radius: 2px
    line-height: .39rem
    text-align: center
    font-size: .14rem
    color: #AEAEAE
    cursor: not-allowed
.getCode3
    width:1.08rem
    height: .39rem
    margin-left: .19rem
    background-color: #00CCCC
    border-radius: 2px
    line-height: .39rem
    text-align: center
    font-size: .14rem
    color: #fff
    cursor pointer
.divIdentifyingCode
    position: absolute
    top: .42rem
    left: 1.56rem
    z-index: 5
    width: 2.6rem
    height: .31rem
    margin: 0
    display: flex
    align-items: center
    .imgIdentifyingCode
        height: .31rem
        width: 1.09rem
        cursor pointer
        background-color: #e2e2e2
        
    .next
        font-size: .11rem
        margin-left: .08rem
        color: #00CCCC
        cursor pointer

//login
.login 
    width:100vw
    height: 100vh
    background-size:cover
    background-repeat: no-repeat
    position: relative
    .icon 
        width: 1.15rem
        height:.19rem
        margin: .22rem 0 0 .37rem
    .loginBox
        width: 4.69rem
        position: absolute
        top:.86rem
        right:1.56rem
        overflow hidden
        box-shadow: 0 2px .09rem 0 rgba(0, 0, 0, 0.1)
        .title 
            font-size:.25rem
            color: #00CCCC
            font-weight: bold 
            line-height: .28rem
        .checkWay
            margin-top: .42rem
            display: flex
        .password
            overflow hidden
            position relative
            .forget
                font-size:.14rem
                color: #0090FF
                cursor: pointer
                line-height: .23rem
                position: absolute
                right 0
                font-family: Source Han Sans CN
            .submit
                width: 3.75rem
                height: .47rem
                background-color: #00CCCC
                margin-top: 1.03rem
                font-size: .16rem
                line-height: .47rem
                color: #FFFFFF
                text-align: center
                border-radius: 2px
                cursor pointer
            .other
                margin-top: .19rem
                margin-bottom: .36rem
                width: 3.75rem
                display: flex
                justify-content: center
                span 
                    font-size: .14rem
                    line-height: .23rem
        .codeL
            .setPhone
                margin-top: .38rem
            .phoneCodeBox
                width: 3.75rem
                display: flex
                align-items: center
                margin-top: .42rem
            .submit
                width: 3.75rem
                height: .47rem
                background-color: #00CCCC
                margin-top: 1.25rem
                font-size: .16rem
                line-height: .47rem
                color: #FFFFFF
                text-align: center
                border-radius: 2px
                cursor pointer
            .other
                margin-top: .19rem
                width: 3.75rem
                margin-bottom: .56rem
                display: flex
                justify-content: center
                span 
                    font-size: .14rem
                    line-height: .23rem
    .icp
        width: 1.56rem
        height: .11rem
        position:absolute
        left:50%
        margin-left: -0.78rem
        bottom: .16rem
        text-align: center
        font-size: .11rem
        color: #353535
        opacity .4
        line-height: .11rem
        margin: .39rem auto 0 auto
        
</style>